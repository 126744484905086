import React from "react";
import "./App.css";
import {
  Row,
  Col,
  Tooltip,
  Input,
  Space,
  Card,
  InputNumber,
  Button,
  Table,
  Tag,
  message,
  Progress,
} from "antd";
import { formatCurrency } from "./format";
import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getDecimal } from "./api";
const API_KEY = "3R8V286MJ1FN6KN3CW1ABYW6KBDQ2J9SVZ";
function App() {
  const [dataSource, setDataSource] = React.useState([]);
  const [address, setAddress] = React.useState([]);
  const [token, setToken] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [ETHSum, setETHSum] = React.useState(0);
  const [AllSum, setAllSum] = React.useState(0);
  const [totalSuply, setTotalSuply] = React.useState(0);
  const [interValue, setInterValue] = React.useState(30);
  const [current, setCurrent] = React.useState(0);
  const inter = React.useRef(null);

  React.useEffect(() => {
    var data = localStorage.getItem("dataLocal");
    console.log(data);
    if (data) {
      data = JSON.parse(data);
      setAddress(data.lstAdd);
      setToken(data.token);
      setInterValue(data.interValue);
    }
  }, []);

  React.useEffect(() => {
    if (inter.current) clearInterval(inter.current);
    inter.current = setInterval(() => {
      checkBalabance();
    }, interValue * 1000);
  }, [interValue, token, address, loading]);

  const columns = [
    {
      title: "Transaction Hash",
      dataIndex: "hash",
      key: "hash",
      render: (text, record, index) => {
        return record.ID;
      },
    },
    {
      title: "Method",
      dataIndex: "Method",
      key: "Method",
      render: (text, record, index) => {
        if (record.functionName && record.functionName != "") {
          return record.functionName.substr(0, record.functionName.indexOf("("));
        }
        return record.methodId;
      },
    },
    {
      title: "Block",
      dataIndex: "blockNumber",
      key: "blockNumber",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      render: (text, record, index) => {
        var day = dayjs.unix(record.timeStamp).format("DD-MM-YYYY HH:mm");
        return day;
      },
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text, record, index) => {
        var url = "https://etherscan.io/tx/" + record.hash;
        return (
          <span>
            {record.from}{" "}
            <a href={url} target="_blank">
              {record.from == record.ID ? (
                <Tag color="warning">Out</Tag>
              ) : (
                <Tag color="success">In</Tag>
              )}
            </a>
          </span>
        );
      },
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (text, record, index) => {
        return record.result;
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record, index) => {
        return record.value / 1000000000000000000;
      },
    },
  ];

  const sleep = function (ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getTransaction = async (address) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=0&sort=desc&apikey=${API_KEY}`,
      requestOptions
    );
    const res = await response.json();
    return res;
  };

  const checkBalabanceByToken = async () => {
    var decimal = await getDecimal(token);
    decimal = decimal.toString().replace("n", "");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    var newList2 = [...dataSource].filter((x) => address.includes(x.ID));
    setDataSource((previewState) => [...newList2]);
    for (const item of address) {
      var response = await fetch(
        `https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${token}&address=${item}&tag=latest&apikey=${API_KEY}`,
        requestOptions
      );
      var result = await response.json();
      if (result.status == "1") {
        // var dataTrans = await getTransaction(item);
        // if (dataTrans.status == "1" && dataTrans.result.length > 0) {
        //   var index = newList2.findIndex((x) => x.ID == item);
        //   if (index !== -1) {
        //     newList2[index] = { ...dataTrans.result[0], result: result.result };
        //     newList2[index].ID = item;
        //   } else {
        //     var newData = { ...dataTrans.result[0], result: result.result };
        //     newData.ID = item;
        //     newList2.push(newData);
        //   }
        // } else {
        //   var index = newList2.findIndex((x) => x.ID == item);
        //   if (index !== -1) {
        //     newList2[index].result = result.result;
        //     newList2[index].ID = item;
        //   } else {
        //     var newData = {};
        //     newData.result = result.result;
        //     newData.ID = item;
        //     newList2.push(newData);
        //   }

        var index = newList2.findIndex((x) => x.ID == item);
        if (index !== -1) {
          newList2[index].result =
            (result.result /
              (1 +
                Array.from(Array(decimal * 1).keys())
                  .map((item) => 0)
                  .join(""))) *
            1;
          newList2[index].ID = item;
        } else {
          var newData = {};
          newData.result =
            (result.result /
              (1 +
                Array.from(Array(decimal * 1).keys())
                  .map((item) => 0)
                  .join(""))) *
            1;
          newData.ID = item;
          newList2.push(newData);
        }
        setDataSource((previewState) => [...newList2]);
        setCurrent((previewState) => previewState + 1);
      }
      await sleep(200);
    }
    const sum = newList2.reduce((accumulator, object) => {
      return accumulator + object.result * 1;
    }, 0);

    var totalres = await fetch(
      `https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=${token}&apikey=${API_KEY}`,
      requestOptions
    );
    var totalResult = await totalres.json();
    if (totalResult.status == "1")
      setTotalSuply(
        (totalResult.result /
          (1 +
            Array.from(Array(decimal * 1).keys())
              .map((item) => 0)
              .join(""))) *
          1
      );

    setLoading(false);
    setAllSum(sum);
    setETHSum(0);
  };

  const checkBalabance = async () => {
    if (loading) return;
    setCurrent((previewState) => 0);
    // setDataSource([]);
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    if (token == "") {
      var newList = [...dataSource].filter((x) => address.includes(x.ID));
      setDataSource((previewState) => [...newList]);
      for (const item of address) {
        var response = await fetch(
          `https://api.etherscan.io/api?module=account&action=balance&address=${item}&tag=latest&apikey=${API_KEY}`,
          requestOptions
        );
        var result = await response.json();
        if (result.status == "1") {
          var dataTrans = await getTransaction(item);
          if (dataTrans.status == "1" && dataTrans.result.length > 0) {
            var index = newList.findIndex((x) => x.ID == item);
            if (index !== -1) {
              newList[index] = {
                ...dataTrans.result[0],
                result: result.result / 1000000000000000000,
              };
              newList[index].ID = item;
            } else {
              var newData = { ...dataTrans.result[0], result: result.result / 1000000000000000000 };
              newData.ID = item;
              newList.push(newData);
            }
          } else {
            var index = newList.findIndex((x) => x.ID == item);
            if (index !== -1) {
              newList[index].result = result.result / 1000000000000000000;
              newList[index].ID = item;
            } else {
              var newData = {};
              newData.result = result.result / 1000000000000000000;
              newData.ID = item;
              newList.push(newData);
            }
          }
          setDataSource((previewState) => [...newList]);
          setCurrent((previewState) => previewState + 1);
        }

        await sleep(200);
      }

      const sum = newList.reduce((accumulator, object) => {
        return accumulator + object.result * 1;
      }, 0);
      console.log(newList, sum);
      setLoading(false);
      setETHSum(sum);
      setAllSum(0);
      setTotalSuply(0);
    } else {
      checkBalabanceByToken();
    }
  };

  const setIntervalReload = (value) => {
    setInterValue(value);
  };

  return (
    <div style={{ backgroundColor: "gray", height: "100vh" }}>
      <Row>
        <Row style={{ margin: 10, width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                <Space direction="horizontal">
                  <div>
                    <b>Token</b>{" "}
                    <Tooltip placement="topLeft" title={"Insert your token address"} arrow={true}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <Input
                    style={{ width: "1000px" }}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  ></Input>{" "}
                  <b>Reset Time (Second)</b>
                  <InputNumber
                    min={30}
                    max={99999}
                    defaultValue={30}
                    value={interValue}
                    onChange={setIntervalReload}
                  />
                </Space>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flex: 1,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  <b>Address List</b>
                  <Button
                    onClick={() => {
                      var dataSave = {
                        lstAdd: address,
                        token: token,
                        interValue: interValue,
                      };
                      localStorage.setItem("dataLocal", JSON.stringify(dataSave));
                      message.success("Thành công");
                    }}
                  >
                    Save
                  </Button>
                </div>

                <Input.TextArea
                  rows={10}
                  value={address.join("\n")}
                  onChange={(e) => {
                    setAddress(e.target.value.split("\n"));
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Row>
        <Row style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", flex: 1, width: "100%" }}>
            <Button type="primary" onClick={checkBalabance} loading={loading}>
              {" "}
              <SearchOutlined />
              Check Balance
            </Button>
          </div>
        </Row>
        <Row style={{ margin: 10, width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <Progress
              percent={((current / (address.length != 0 ? address.length : 1)) * 100).toFixed(2)}
            />
            <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
              <div>
                <span>All ETH balance: {ETHSum}</span>
              </div>
              <div>
                <span>
                  All balance:{" "}
                  {formatCurrency(AllSum) +
                    "/" +
                    formatCurrency(totalSuply) +
                    "(" +
                    ((AllSum * 1) / (totalSuply * 1)) * 100 +
                    "%)"}
                </span>
              </div>
            </div>
          </Card>
        </Row>
        <Row style={{ margin: 10, width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <Table
              dataSource={dataSource}
              columns={columns}
              loading={false}
              size="small"
              pagination={{ pageSize: 500 }}
            />
          </Card>
        </Row>
      </Row>
    </div>
  );
}

export default App;
