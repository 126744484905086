import { Web3 } from "web3";

var rpcURL = "https://rpc.ankr.com/eth";
async function getDecimal(token) {
  const web3 = new Web3("https://mainnet.infura.io/v3/dc8b83e240bb417e89069055c5223239");

  const abi_totalSupply = await getABI(token);

  const c_address = token;
  const contract = new web3.eth.Contract(abi_totalSupply, c_address);

  // Call the `decimals` function on the contract
  var res = await contract.methods.decimals().call();

  return res;
}

async function getABI(token) {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  var res = await fetch(
    `https://api.etherscan.io/api?module=contract&action=getabi&address=${token}&apikey=3R8V286MJ1FN6KN3CW1ABYW6KBDQ2J9SVZ`,
    requestOptions
  );
  var result = await res.json();
  return JSON.parse(result.result);
}

export { getDecimal };
